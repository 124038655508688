import React, {Suspense} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/style.css'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './components/Loading';
import AdminRouter from './admin/Router';


const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Checkout = React.lazy(() => import('./pages/Checkout'));
const ProductItem = React.lazy(() => import('./pages/ProductItem'));
const Thankyou = React.lazy(() => import('./pages/Thankyou'));
const HowItWorks = React.lazy(() => import('./pages/HowItWorks'));
const Faq = React.lazy(() => import('./pages/Faq'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));

const DefaultLayout = React.lazy(() => import('./admin/layout/DefaultLayout'))
const Login = React.lazy(() => import('./admin/Login'))
const ForgotPassword = React.lazy(() => import('./admin/ForgotPassword'))
const NotFound = React.lazy(() => import('./pages/404'))


const App = () => {
  return (
    <>
      <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/checkout" element={<Checkout />}/>
            <Route path="/thankyou" element={<Thankyou />}/>
            <Route path="/how-it-works" element={<HowItWorks />}/>
            <Route path="/product/:id" element={<ProductItem />}/>
            <Route path="/faq" element={<Faq />}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/admin/*" element={<DefaultLayout />} />
            <Route path="*" element={<NotFound/>}  />

          </Routes>
          <AdminRouter />
            <ToastContainer 
              position="bottom-left"
              autoClose={5000}
              />
      </Suspense>
    </>
  )
}

export default App