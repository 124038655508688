import React from 'react'
// import {Routes, Route} from 'react-router-dom';
// import Login from './Login';
// import ForgotPassword from './ForgotPassword';
// import PasswordReset from './PasswordReset';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss';
import './assets/css/crm.css';

// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// )

// Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// const Login = React.lazy(() => import('./Login'))
// const ForgotPassword = React.lazy(() => import('./ForgotPassword'))



const AdminDashboard = () => {
  return (
    <React.StrictMode>
            {/* <Routes>
                <Route path="/admin/" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/admin/*" element={<DefaultLayout />} />
            </Routes> */}
            {/* <BasicToast/> */}
            <ToastContainer 
            position="bottom-left"
            autoClose={5000}
            />

    </React.StrictMode>
  )
}

export default AdminDashboard